import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import './utils/normalize.less'
import SimpleToast from "@/views/components/SimpleToast";
import './utils/smoothScrollPolyfill'
// import {Swipe, SwipeItem} from 'vant';


const app = createApp(App)
history.scrollRestoration = 'manual'// 刷新后不记忆滚动位置
app.config.globalProperties.$toast = SimpleToast;
app
    // .use(Swipe)
    // .use(SwipeItem)
    .use(router)
    .mount('#app')
