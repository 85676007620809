import {createVNode, render} from "vue";
import SimpleToast from "./SimpleToast.vue";

interface SimpleToastParams {
  text: string,
  duration?: number,
  type?: 'success' | 'error'
}

const div = document.createElement("div");
document.body.appendChild(div);

let time: NodeJS.Timeout | null = null;
export default ({text, duration = 3000, type = 'success'}: SimpleToastParams) => {
  const NODE = createVNode(SimpleToast, {text,duration,type});
  render(null, div);
  render(NODE, div);
  if (time) {
    clearTimeout(time);
  }
  time = setTimeout(() => {
    render(null, div);
  }, duration);
};
