export const pxAndRemRate = 75 // 转换比例 需要和package.json中 postcss-pxtorem 插件的 rootValue值保持一致
export const pcMinWidth = 1000
export const pcMaxWidth = 1920


export const isMobile = () => {
    const info = navigator.userAgent;
    const agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod", "iPad"];
    for (let i = 0; i < agents.length; i++) {
        if (info.indexOf(agents[i]) >= 0) {
            return true;
        }
    }
    return false;
}

export const siteDesignWidth = () => isMobile() ? 1920 : 1920

export const siteMinWidth = () => isMobile() ? document.body.clientWidth : pcMinWidth  //px

export const siteMaxWidth = () => isMobile() ? document.body.clientWidth : pcMaxWidth

export const siteCurrentWidth = () => isMobile() ? document.body.clientWidth : Math.min(Math.max(document.documentElement.clientWidth, pcMinWidth), pcMaxWidth)

export const getRootFontSize = () => siteCurrentWidth() / siteDesignWidth() * pxAndRemRate

export const getScaleRate = () => siteCurrentWidth() / siteDesignWidth()
