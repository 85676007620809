import { toDisplayString as _toDisplayString, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock(_Transition, {
    name: "down"
  }, {
    default: _withCtx(function () {
      return [_withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["simple-toast", ['simple-toast--' + $props.type, {
          'simple-toast--mobile': $setup.isMobile()
        }]])
      }, _toDisplayString($props.text), 3), [[_vShow, $setup.visible]])];
    }),
    _: 1
  });
}